import React from 'react';
import LongBlogAndPressContentHeader from '../LongBlogAndPressContentHeader';
import LongBlogAndPressContentOurCompany from '../LongBlogAndPressContentOurCompany';
import CategorySocialMedia from '../CategorySocialMedia';
import { myStyles } from './style';
import wordCountProvider from '../word-count/word-count';
import clsx from 'clsx';
import './LongPress.scss';
import { LongPressWordCount } from './LongPressWordCount';

const LongPressContent = ({ data, bucketname, pressSlug, basePath, images,location }) => {
  const path = `${pressSlug}`;
  const landingUrl = process.env.GATSBY_BLOG_LANDING_URL;
  const classes = myStyles(images);
  const TotalContent=LongPressWordCount.WordCount(data);
  const wordCount = wordCountProvider(TotalContent);
   return (
    <>
      <div className="long-press-main" id={data?.entryTitle}>
        <div className={clsx('donuts-right', classes['bg-donuts-right'])}>
          <LongBlogAndPressContentHeader
            data={data}
            title={data?.pressContentTitle}
            images={images}
          />
        </div>
        {(data?.rightSectionContent?.raw ||
          data?.leftSectionContent?.raw) && (
          <LongBlogAndPressContentOurCompany
            leftSection={data?.leftSectionContent?.raw}
            rightSection={data?.rightSectionContent?.raw}
          />
        )}
        <CategorySocialMedia
          data={data}
          bucketname={bucketname}
          type={process.env.GATSBY_CARTUS_PRESS}
          path={path}
          wordCount={wordCount}
          title={data?.pressContentTitle}
          landingUrl={landingUrl}
          basePath={basePath}
          images={images}
          location={location}
        />
      </div>
    </>
  );
};
export default LongPressContent;
