class LongPressWordCount {
    static generateTotalContent(element,TotalContent) {
        element.forEach((el) => {
          el?.content.forEach((childElement) => {
            TotalContent = TotalContent + ' ' + childElement?.value?.trim();
          });
        });
        return TotalContent;
      }
    static WordCount(data) {
        let TotalContent = '';
        if (data?.richTextContent1?.raw) {
            TotalContent= this.generateTotalContent(JSON.parse(data?.richTextContent1?.raw).content,TotalContent);
          }
          if (data?.subText?.raw) {
            TotalContent= this.generateTotalContent(JSON.parse(data?.subText?.raw).content,TotalContent);
          }
          if (data?.richTextContent2?.raw) {
            TotalContent= this.generateTotalContent(JSON.parse(data?.richTextContent2?.raw).content,TotalContent);
          }
          if (data?.richTextContent3?.raw) {
            TotalContent= this.generateTotalContent(JSON.parse(data?.richTextContent3?.raw).content,TotalContent);
          }
          if (data?.richTextHeaderWithBulletPoints?.raw) {
            const bulletContent = JSON.parse(data?.richTextHeaderWithBulletPoints?.raw).content.filter(
              (element) => element.nodeType === 'unordered-list' || element.nodeType === 'ordered-list'
            );
            const nonBulletContent = JSON.parse(data?.richTextHeaderWithBulletPoints?.raw).content.filter(
              (element) => element.nodeType !== 'unordered-list' && element.nodeType !== 'ordered-list'
            );
            bulletContent.forEach((ele) => {
              ele.content.forEach((item) => {
                TotalContent= this.generateTotalContent(item.content,TotalContent);
              });
            });
        
            TotalContent= this.generateTotalContent(nonBulletContent,TotalContent);
          }
        
          if (data?.leftSectionContent?.raw) {
            TotalContent= this.generateTotalContent(JSON.parse(data?.leftSectionContent?.raw).content,TotalContent);
          }
          if (data?.rightSectionContent?.raw) {
            TotalContent= this.generateTotalContent(JSON.parse(data?.rightSectionContent?.raw).content,TotalContent);
          }
          return TotalContent;
    } 
    
}
export {LongPressWordCount };