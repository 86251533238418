import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
    "bg-donuts-right": (images) => ({
        background: `url(${images?.greenDonutBackgroundImage?.file.url}?fm=webp&q=100) no-repeat`,
      }),

      "bg-capsule" :(images) => ({
        background: `url(${images?.capsulesBackgroundImage?.file.url}?fm=webp&q=100) no-repeat`,
      })

}))