import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    "bg-donuts-right-img": (images) => ({
        background: `url(${images?.blueDonutBackgroundImage?.file.url}?fm=webp&q=100) no-repeat`,
    }),

    "bg-capsule-left": (images) => ({
        background: `url(${images?.capsulesBackgroundImage?.file.url}?fm=webp&q=100) no-repeat`,
    })
}))
