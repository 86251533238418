import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo-component/seo';
import PressContent from '../components/PressContent';

export default function PressDetailsPage({location ,pageContext, data }) {
  const [user, setUser] = useState({});
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };
  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };
  return (
    <>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={allFooterData}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
        basePath={pageContext.basePath}
        bucketName={pageContext?.bucketName}
        location={location}
      >
        <SEO
          data={data?.contentfulPressRoom?.seoEntry}
          siteurl={pageContext?.siteurl}
          bucketName={pageContext?.bucketName}
          basePath={pageContext.basePath}
          organizationName = {pageContext?.appData?.organizationName}
          organizationLogo = {pageContext?.appData?.organizationLogo?.file?.url}
          organizationLinkedinLink = {pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink = {pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink = {pageContext?.appData?.organizationYoutubeLink}
          location={location}
        />
        <PressContent
          data={data?.contentfulPressRoom}
          bucketname={pageContext?.bucketName}
          basePath={pageContext.basePath}
          images={data?.contentfulComponentBackgroundImages}
          location={location}
        />
      </Layout>
    </>
  );
}
export const query = graphql`
  query PressDetailsTemplate($contentful_id: String, $locale: String) {
    contentfulComponentBackgroundImages {
      blueDonutBackgroundImage {
        file {
          url
        }
      }
      capsulesBackgroundImage {
        file {
          url
        }
      }
      dotMatrixBackgroundImage {
        file {
          url
        }
      }
      downloadArrowImage {
        description
        file {
          url
        }
      }
      greenDonutBackgroundImage {
        file {
          url
        }
      }
      gridBridgeImage {
        file {
          url
        }
      }
      linkedlnIcon {
        description
        file {
          url
        }
      }
      twitterIcon {
        description
        file {
          url
        }
      }
      emailIcon {
        description
        file {
          url
        }
      }
      facebookIcon {
        description
        file {
          url
        }
      }
      sys {
        contentType {
          sys {
            type
          }
        }
      }
    }

    contentfulPressRoom(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      id
      entryTitle
      contentful_id
      slug
      seoEntry {
        pageTitle
        metaDescription {
          metaDescription
        }
        metaKeywords
        metaRobotsTag
        ogType
        ogSiteName
        defaultOgLocale
        altimageTag
        ogImage {
          title
          file {
            url
          }
        }
      }
      pressReleaseConent {
        ...shortpress
        ...longpress
      }
      sys {
        contentType {
          sys {
            id
          }
        }
      }
    }
  }

  fragment shortpress on ContentfulPressReleaseShortContent {
    thumbnailImage {
      description
      file {
        url
      }
    }
    categoryType
    dateOfPublication(locale: "us")
    pressReleaseTitle
    heroImage {
      description
      file {
        url
      }
    }
    topic
    subTopic
    description {
      raw
    }
    displayPrimaryCta
    labelForPrimaryCta
    downloadPdf {
      file {
        url
      }
    }
    richTextContent1 {
      raw
    }
    richTextContent2 {
      raw
    }
    rightSectionParagraphText {
      raw
    }
    leftSectionTopic {
      raw
    }
    leftSectionSubTopic {
      raw
    }
    leftSectionImage {
      description
      file {
        url
      }
    }
    rightSectionImage {
      description
      file {
        url
      }
    }
    sys {
      contentType {
       sys {
          id
        }
      }
    }
  }

  fragment longpress on ContentfulPressReleaseLongContent {
    id
    contentful_id
    entryTitle
    categoryType
    pressContentTitle
    dateOfPublication
    pressContentTitle
    heroImage {
      description
      title
      file {
        url
      }
    }
    thumbnailImage {
      description
      file {
        url
      }
    }
    richTextContent1 {
      raw
    }
    title
    subTitle
    subText {
      raw
    }
    downloadPdf {
      file {
        url
      }
    }
    displayPrimaryCta
    labelForPrimaryCta
    richTextContent2 {
      raw
    }
    primaryVideoOrImage
    primaryImage {
      description
      title
      file {
        url
      }
    }
    richTextContent3 {
      raw
    }
    secondaryVideoOrImage
    secondaryImage {
      description
      title
      file {
        url
      }
    }
    richTextHeaderWithBulletPoints {
      raw
    }
    leftSectionContent {
      raw
    }
    rightSectionContent {
      raw
    }
    primaryVideo {
      ...compVideo
    }
    secondaryVideo {
      ...compVideo
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }

  fragment compVideo on ContentfulCustomVideo {
   sys {
      contentType {
        sys {
          id
        }
      }
    }
    entryTitle
    videoComponent {
      entryTitle
      videoType
      videoId
      videoTitle
      dateOfPublishing
    }
  }
`;
