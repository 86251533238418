/* eslint-disable import/no-cycle */
import React from 'react';
import LongPressContent from '../LongPressContent';
import ShortPress from '../ShortPress'

const PressContent=({data,bucketname,basePath,images,location})=> {
  let pressType = data?.pressReleaseConent?.sys?.contentType?.sys?.id
  const renderPressContent = (pressType) => {
    switch (pressType) {
      case "pressContentLongBlog":
        return (
          <LongPressContent
          data={data?.pressReleaseConent}
          bucketname={bucketname}
          pressType={pressType}
          pressSlug={data?.slug}
          basePath={basePath}
          images={images}
          location={location}
        />
        )
      case "pressContentShortBlog":
        return (
          <ShortPress
          data={data.pressReleaseConent}
          path={data.slug}
          bucketname={bucketname}
          blogtype={pressType}
          basePath={basePath}
          images={images}
          location={location}
          />
        )
      default:
        return (
          <h1>Select a proper type</h1>
        )
    }
  }
  return (
    <>
      {renderPressContent(pressType)}
    </>
  );
}

export default PressContent