import React from 'react';
import { useStyles } from './styles';
import wordCountProvider from '../word-count/word-count';
import clsx from 'clsx';
import './ShortPress.scss';
import ShortBlogAndPressContentHeader from '../ShortBlogAndPressContentHeader';
import CategorySocialMedia from '../CategorySocialMedia';

const ShortPress = ({ data, path, bucketname, basePath,images,location}) => {
  const classes = useStyles(images);
  const pathName = `${path}`;
  let TotalContent = '';
  function generateTotalContent(element) {
    if(element){
      element.forEach((el) => {
        if(el?.content){
          el?.content.forEach((childElement) => {
            TotalContent = TotalContent + ' ' + childElement?.value?.trim();
          });
        }
      });
    }
  }

  if (data?.richTextContent1) {
    generateTotalContent(JSON.parse(data?.richTextContent1?.raw).content);
  }
  if (data?.richTextContent2) {
    generateTotalContent(JSON.parse(data?.richTextContent2?.raw).content);
  }
  if (data?.rightSectionParagraphText) {
    generateTotalContent(JSON.parse(data?.rightSectionParagraphText?.raw).content);
  }
  if (data?.description) {
    generateTotalContent(JSON.parse(data?.description?.raw).content);
  }
  return (
    <>
    <div className="short-press-main">
      <div className={clsx('bg-donuts-right', classes['bg-donuts-right-img'])}>
        <ShortBlogAndPressContentHeader data={data} images={images} title={data?.pressReleaseTitle}/>
        <CategorySocialMedia
          data={data}
          bucketname={bucketname}
          blogtype={process.env.GATSBY_CARTUS_PRESS}
          path={pathName}
          title={data?.pressReleaseTitle}
          wordCount={wordCountProvider(TotalContent)}
          landingUrl=''
          basePath={basePath}
          images={images}
          location={location}
        />
      </div>
    </div>
  </>
  )
}

export default ShortPress